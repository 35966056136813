.projects {
    overflow: hidden;
    display: block;
    width: 85%;
    margin-left: 2%;
    padding: 50px;
}

#projects-bar {
    background-color: #c1d045;
    height: 7px;
    border: none;
    width: 105%;    
}

.projects a {
    color:white;
}

.projects h1 {
    font-size: 32px;
    font-weight: initial;
}
.wrapper{
    display: flex;
    flex-wrap: wrap;
    
}
.card {
    width: fit-content;
    height: fit-content;
    margin: 50px;
    display: block;
    border: #c0c0c0 3px;
    border-style: solid;
    border-radius: 8px;
}
#card-image {
    width: 267px;
    margin: auto;
 }

#card-desc {
    background-color: #c1d045;
    color:white;
    border-radius: 4px;
    padding: 15px;
    width: 267px;    
}
#card-desc h2 {
    text-align: center;
    font-size: 21px;
}
#card-desc p {
    font-size: 13px;
}
.projects img {
    height: 127px;
    width: 254px;
    padding: 14px;
    
}
#card-desc svg {
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 6px;
    background-color: #c0c0c0;
    width:31%;
    height: 21px;
}