.landing-contents {
    border-color: #c1d045;
    background-color: #c1d045;
    border-style: solid;
    border-width: 50px;
    border-left: 0;
    border-radius: 50%;
    margin-top: 1%;
    width: 653px;
    height: 507px;
    margin-left: 33%;
    padding: 100px;
    display: flex; 
}
.landing-wrapper{
    display: flex;
    position: absolute;
}
.description {
    display: flex;
    align-items: center;
    margin-left: -574px;
}
.photo {
    margin-top: 13%;
    margin-left: -31%;
    padding: 10px;
}
.paragraph p {
width: max-content;
}

.paragraph {
    font-size: 37px;
    color: #b3b3b3;
    background-color: white;
    padding: 11%;
}

#name {
    color: black;
}

#landing-photo {
    width: 593px;
    border-radius: 14px;
}

.right-nav svg {
    width: 37px;
    height: 37px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left: 695%;
    margin-top: 15%;
    display: inline-block;
}

.right-nav {
    display: flex;
    align-items: center;
}
.right-nav li {
    display: block;
    padding: 33px 3px;
}

#githublink {
    color: black;
    text-decoration: none;
}
#linkedinlink {
    color: black;
    text-decoration: none;
}

#maillink {
    color: black;
    text-decoration: none;
}