body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
    background-color: #fff;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 0 5rem;
}

.site-title {
    font-size: 2rem;
}
.navbar ul {
    padding: 0 6em;
    margin: 0;
    list-style: none;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem; 
    font-size: 19px;
}
.navbar a {
    color: inherit;
    text-decoration: none; 
    height: 100%;
    display: flex;
    align-items: center; 
    padding: .25rem;
}

.navbar li.active {
    background-color: #555;
}

.navbar li:hover {
    background-color: #777;
}

.navbar h1 {
    font-weight: lighter;
    font-size: 28px;
}

.navbar li {
    padding: 20px;
}
.landing-contents {
    border-color: #c1d045;
    background-color: #c1d045;
    border-style: solid;
    border-width: 50px;
    border-left: 0;
    border-radius: 50%;
    margin-top: 1%;
    width: 653px;
    height: 507px;
    margin-left: 33%;
    padding: 100px;
    display: flex; 
}
.landing-wrapper{
    display: flex;
    position: absolute;
}
.description {
    display: flex;
    align-items: center;
    margin-left: -574px;
}
.photo {
    margin-top: 13%;
    margin-left: -31%;
    padding: 10px;
}
.paragraph p {
width: -webkit-max-content;
width: max-content;
}

.paragraph {
    font-size: 37px;
    color: #b3b3b3;
    background-color: white;
    padding: 11%;
}

#name {
    color: black;
}

#landing-photo {
    width: 593px;
    border-radius: 14px;
}

.right-nav svg {
    width: 37px;
    height: 37px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left: 695%;
    margin-top: 15%;
    display: inline-block;
}

.right-nav {
    display: flex;
    align-items: center;
}
.right-nav li {
    display: block;
    padding: 33px 3px;
}

#githublink {
    color: black;
    text-decoration: none;
}
#linkedinlink {
    color: black;
    text-decoration: none;
}

#maillink {
    color: black;
    text-decoration: none;
}
.about {
    display: block;
    width: 85%;
    margin-left: 2%;
    padding: 42px;
}

.about #bar {
    background-color: #c1d045;
    height: 6px;
    border: none;
    width: 105%;    
}

#about-photo {
    float:right;
    padding: 35px;
    width: 333px;
}

.about h1 {
    font-size: 32px;
    font-weight: initial;
}

.about #but {
    font-size: 26px;
}

.about-footer {
    background-color: #c1d045;
    padding: 17px;
    text-align: center;
    font-size: 15px;
    margin-top: 2%;
    padding-bottom: 1px;
    color: white;
}

.about-footer svg {
    padding: 0px 10px;
    width: 32px;
    height: 32px;
}
.about p {
    font-size: 19px;
    color: #b3b3b3;
    
}

body {
    overflow: visible;
}

#github {
    color: white;
    text-decoration: none;
}
#linkedin {
    color: white;
    text-decoration: none;
}
.projects {
    overflow: hidden;
    display: block;
    width: 85%;
    margin-left: 2%;
    padding: 50px;
}

#projects-bar {
    background-color: #c1d045;
    height: 7px;
    border: none;
    width: 105%;    
}

.projects a {
    color:white;
}

.projects h1 {
    font-size: 32px;
    font-weight: initial;
}
.wrapper{
    display: flex;
    flex-wrap: wrap;
    
}
.card {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 50px;
    display: block;
    border: #c0c0c0 3px;
    border-style: solid;
    border-radius: 8px;
}
#card-image {
    width: 267px;
    margin: auto;
 }

#card-desc {
    background-color: #c1d045;
    color:white;
    border-radius: 4px;
    padding: 15px;
    width: 267px;    
}
#card-desc h2 {
    text-align: center;
    font-size: 21px;
}
#card-desc p {
    font-size: 13px;
}
.projects img {
    height: 127px;
    width: 254px;
    padding: 14px;
    
}
#card-desc svg {
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 6px;
    background-color: #c0c0c0;
    width:31%;
    height: 21px;
}
