.about {
    display: block;
    width: 85%;
    margin-left: 2%;
    padding: 42px;
}

.about #bar {
    background-color: #c1d045;
    height: 6px;
    border: none;
    width: 105%;    
}

#about-photo {
    float:right;
    padding: 35px;
    width: 333px;
}

.about h1 {
    font-size: 32px;
    font-weight: initial;
}

.about #but {
    font-size: 26px;
}

.about-footer {
    background-color: #c1d045;
    padding: 17px;
    text-align: center;
    font-size: 15px;
    margin-top: 2%;
    padding-bottom: 1px;
    color: white;
}

.about-footer svg {
    padding: 0px 10px;
    width: 32px;
    height: 32px;
}
.about p {
    font-size: 19px;
    color: #b3b3b3;
    
}

body {
    overflow: visible;
}

#github {
    color: white;
    text-decoration: none;
}
#linkedin {
    color: white;
    text-decoration: none;
}