.navbar {
    background-color: #fff;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 5rem;
}

.site-title {
    font-size: 2rem;
}
.navbar ul {
    padding: 0 6em;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem; 
    font-size: 19px;
}
.navbar a {
    color: inherit;
    text-decoration: none; 
    height: 100%;
    display: flex;
    align-items: center; 
    padding: .25rem;
}

.navbar li.active {
    background-color: #555;
}

.navbar li:hover {
    background-color: #777;
}

.navbar h1 {
    font-weight: lighter;
    font-size: 28px;
}

.navbar li {
    padding: 20px;
}